import React from "react";
import { useHistory } from "react-router-dom";

import { Box } from "@chakra-ui/react";

import { initStep } from "../../shared/ui/lib/steps";

import { Button, Result } from "antd";
import { getAnalytics, logEvent } from "firebase/analytics";

export const Page404 = () => {
  const history = useHistory();
  React.useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "page_404");
  }, []);

  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='404'
        title='404'
        subTitle='К сожалению, посещенная вами страница не существует.'
        extra={
          <Button type='primary' onClick={() => history.replace(initStep.route)}>
            Вернуться домой
          </Button>
        }
      />
    </Box>
  );
};
