import React from 'react';
import { observer } from 'mobx-react-lite';

export const SMSConfirmPage = observer(() => {
  return <></>;
});

/* const SubTitle = styled(Heading)`
  @media (min-width: 1025px) {
    font-size: var(--chakra-fontSizes-md);
  }
  @media (max-width: 1024px) {
    font-size: var(--chakra-fontSizes-xl);
  }
  @media (max-width: 425px) {
    font-size: var(--chakra-fontSizes-md);
  }
`; */
