import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { Routes } from "../shared/enums/routes";
import { Page404 } from "./404/index";
import { ErrorPage } from "./error";
import { InitService } from "./init-service";
import { SelectCity } from "./selec-city";
import { SelectCompany } from "./select-company";
import { SelectDate } from "./select-date";
import { SelectZonePage } from "./select-zone/index";
import { SMSConfirmPage } from "./sms-confirm";
import { SuccessPage } from "./success";
import { Welcome } from "./welcome";
import { PreparationPage } from "./preparation";
import { Home } from "./home";

export const AppRouter = () => {
  useEffect(() => {
    const onResize = () => {
      let innerHeight = window.innerHeight;
      document.documentElement.style.setProperty("--app-height", `${innerHeight}px`);
    };

    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.Home} component={Home} />
        <Route exact path={Routes.Welcome + "/:formId"} component={Welcome} />
        <Route exact path={Routes.Preparation} component={PreparationPage} />
        <Route exact path={Routes.Error} component={ErrorPage} />
        <Route exact path={Routes.SelectCity} component={SelectCity} />
        <Route exact path={Routes.SelectCompany + "/:cityName"} component={SelectCompany} />
        <Route exact path={Routes.SelectDate} component={SelectDate} />
        <Route exact path={Routes.SelectZone} component={SelectZonePage} />
        <Route exact path={Routes.SMSConfirm} component={SMSConfirmPage} />
        <Route exact path={Routes.Success} component={SuccessPage} />
        <Route path={Routes.Page404} component={Page404} />
        <Route exact path={Routes.InitService + "/:formId"} component={InitService} />
        <Redirect from='*' to={Routes.Page404} />
      </Switch>
    </BrowserRouter>
  );
};
