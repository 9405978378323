import React from 'react';

import { observer } from 'mobx-react-lite';

export const variants_diod = [
  {
    title: 'Одна любая зона за \n 600 руб',
    id: '10549216',
    id_kto: '11252881',
    cost: 600,
  },
  {
    title: 'Две любые зоны за \n 1200 руб',
    id: '10549263',
    id_kto: '11252889',
    cost: 1200,
  },
  {
    title: 'Три любые зоны за \n 1800 руб',
    id: '10549275',
    id_kto: '11252896',
    cost: 1800,
  },
  {
    title: 'Четыре любые зоны за \n 2400 руб',
    id: '10549282',
    id_kto: '11252900',
    cost: 2400,
  },
];

export const variants_alex = [
  {
    title: 'Одна любая зона за \n 890 руб',
    id: '11260230',
    id_kto: '11252881',
    cost: 890,
  },
  {
    title: 'Две любые зоны за \n 1780 руб',
    id: '11260233',
    id_kto: '11252889',
    cost: 1780,
  },
  {
    title: 'Три любые зоны за \n 2670 руб',
    id: '11260235',
    id_kto: '11252896',
    cost: 2670,
  },
  {
    title: 'Четыре любые зоны за \n 3560 руб',
    id: '11260246',
    id_kto: '11252900',
    cost: 3560,
  },
];

export const Welcome = observer(() => {
  return <></>;
});
